import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TSystem } from '../types';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private titleService: Title
  ) { }

  // 获取系统
  getSystemSync(): TSystem {
    return (window as any).system;
  }

  // 获取数小福src
  getShuXiaoFuUrlSync(): string {
    return 'https://iftappwelink.foton.com.cn/ke_fu_ui/shu_xiao_fu_pc.html?groupId=' + (window as any).groupId+'&sysId='+ (window as any).sysId;
  }

  getSsoLoginUrlSync(): string {
    return 'REMS' === this.getSystemSync() ? `/sso/go/rems` : `/sso/go`;
  }

  getSsoLogoutUrlSync(): string {
    return 'REMS' === this.getSystemSync() ? `/sso/logout/rems` : `/sso/logout`;
  }

  getZentaoUrlSync(): string {
    return (window as any).zentaoUrl;
  }

  // 设置页面title
  initTitle() {
    const title = 'REMS' === this.getSystemSync() ? '需求工程管理系统REMS' : '产品创新项目管理系统PCMS';
    this.titleService.setTitle(title);
  }
}
