<!-- <div class="header" *ngIf="!isLogin">
  <app-header></app-header>
</div> -->
<div class="body">
  <div class="body-layout">
    <div class="sider" *ngIf="!isOpenInZentao && setupIsDone">
      <app-left-nav-zentao></app-left-nav-zentao>
      <!-- <div class="text-center">
        <button (click)="switchTheme()">zentao-blue</button>
      </div> -->
    </div>
    <div class="content">
      <app-account *ngIf="!isOpenInZentao && setupIsDone"></app-account>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="hidden">
  <app-check-user></app-check-user>
  <app-check-users></app-check-users>
  <app-check-project></app-check-project>
  <app-turn></app-turn>
  <app-chat></app-chat>
  <app-digital-deliverable></app-digital-deliverable>
  <app-achievement-target></app-achievement-target>
  <app-save-notify></app-save-notify>
  <app-dialog></app-dialog>
  <app-add-organization-chart-item></app-add-organization-chart-item>
  <app-check-organization-users></app-check-organization-users>
</div>

<div class="alerts">
  <app-alert></app-alert>
</div>

<div id="timeStamp">{{ timeStamp }}</div>
<div *ngIf="isLoading" class="loading_bg">
  <i class="fa fa-spinner fa-pulse fa-5x fa-fw loading"></i>
</div>

<!-- 数小福 -->
<!-- <app-shuxiaofu></app-shuxiaofu> -->
