import { Injectable } from '@angular/core';
import { IMenu } from '../models';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemService } from './system.service';
import _ from 'lodash';
import { Router } from '@angular/router';
import { MAIN_ROUTES } from '../consts';

@Injectable({
  providedIn: 'root'
})
export class ZentaoMenuService {

  private baseUrl: string = `${location.origin}`;

  constructor(
    private userService: UserService,
    private systemService: SystemService,
    private router: Router,
  ) { }

  getMenuItems(): Observable<IMenu[]> {
    const menus = [
      { id: 'portal', icon: 'mdi-home', title: '首页', url: `${this.baseUrl}/#/portal` },
      { id: 'work-center', icon: 'mdi-worker', title: '工作中心', url: `${this.baseUrl}/#/work-center` },
      { id: 'project-center', icon: 'mdi-format-list-numbered', title: '项目中心', url: `${this.baseUrl}/#/project-center` },
      { id: 'report', icon: 'mdi-chart-areaspline-variant', title: '报表中心', url: `${this.baseUrl}/#/report` }
    ];
    return this.userService.hasPermissionToMaintainLaborCost().pipe(
      map(hasPermissionToMaintainLaborCost => {
        if (this.showManagementCenter(hasPermissionToMaintainLaborCost)) {
          return _.concat(menus, [{
            id: 'management-center', icon: 'mdi-cogs', title: '管理中心', url: `${this.baseUrl}/#/management-center`
          }]);
        }
        return menus;
      })
    )
  }

  getActiveMenuItem() {
    // 获取当前激活的菜单 ID
    const url = location.href;
    const mainRoutes = MAIN_ROUTES;
    const mainRoute = _.find(mainRoutes, route => {
      return -1 !== url.indexOf(route);
    })
    return mainRoute;
  }

  activeMenuItem(id: string) {
    // 客户端点击一级菜单时调用
    // 返回操作结果成功或失败
    window.location.href = `/#/${id}`;
    return true;
  }

  // 主题先不处理，禅道在客户端只有一种主题青春蓝
  setTheme(theme: string) {
    // 客户端切换主题时调用
    // 返回操作结果成功或失败
    return true;
  }

  // 语言不处理
  setLanguage(lang: string) {
    // 客户端切换语言时调用
    // 返回操作结果成功或失败
    return true;
  }

  // 有权限查看“管理中心”
  showManagementCenter(hasPermissionToMaintainLaborCost: boolean) {
    const isSystemAdmin = this.userService.getCurrentIsSystemAdminSync();
    const isDivisionAdmin = this.userService.getCurrentIsDivisionAdminSync();
    const isCategoryAdmin = this.userService.getCurrentIsCategoryAdminSync();
    const isOrganizationAdmin = this.userService.getCurrentIsOrganizationAdminSync();
    const isProjectManagementAdmin = this.userService.getCurrentIsProjectManagementAdminSync();
    const isSecondOrgTemplateAdmin = this.userService.getCurrentIsSecondOrgTemplateAdminSync();
    const isKpiApprovalAdmin = this.userService.getCurrentIsKpiApprovalAdminSync();
    const isRequirementManagementEngineer = this.userService.getCurrentIsRequirementManagementEngineerSync();
    const system = this.systemService.getSystemSync();
    const permission = isSystemAdmin ||
      isDivisionAdmin ||
      isCategoryAdmin ||
      isOrganizationAdmin ||
      isSecondOrgTemplateAdmin ||
      isKpiApprovalAdmin ||
      isProjectManagementAdmin ||
      hasPermissionToMaintainLaborCost;
    // 'PCMS'系统时，需求管理工程师无权限；'ALL'系统时，需求管理工程师有权限
    return system === 'PCMS' ? permission : permission || isRequirementManagementEngineer;
  }

}
